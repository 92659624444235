import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { PortfolioPage, Summary, Review, Aside, Notes } from "../../../components/mdx/portfolio";
import { Border } from "../../../components/widgets/container";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PortfolioPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{props.pageContext.frontmatter.author} {props.pageContext.frontmatter.title}</h1>
    <Summary mdxType="Summary">
      <Aside mdxType="Aside">
        <p><strong parentName="p">{` Key Facts `}</strong></p>
        <ul>
          <li parentName="ul">{`Volatility-targeting strategy`}</li>
          <li parentName="ul">{`invests in ETFs tracking sector ETFs and treasury bonds`}</li>
          <li parentName="ul">{`rebalances weekly`}</li>
        </ul>
        <p><strong parentName="p">{` Similar Strategies `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-vix-spritz/"
            }}>{`VIX Spritz`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-round-robin/"
            }}>{`Round Robin`}</a></li>
        </ul>
      </Aside>
      <Border mdxType="Border">
        <p>{`This strategy is `}<strong parentName="p">{`outdated`}</strong>{` and requires improvements to better cope with environments of rising bond yields. In the meantime, we suggest using `}<a parentName="p" {...{
            "href": "/portfolios/tt-vix-spritz/"
          }}>{`VIX Spritz`}</a>{` as a replacement.`}</p>
      </Border><br />
      <p><em parentName="p">{`Equal Voltage`}</em>{` is a proprietary premium strategy by `}<em parentName="p">{`TuringTrader.com`}</em>{`, introduced in November 2019.`}</p>
      <p><em parentName="p">{`Equal Voltage`}</em>{` aims to provide stock-like returns, with a volatility comparable to a passive 60/40 portfolio. To achive its objective, the stratregy invests in a menu of 11 S&P 500 sector ETFs, using volatility targeting to determine the exposure. Excess capital is invested in U.S. treasuries as a safer alternative.`}</p>
      <p><em parentName="p">{`Equal Voltage`}</em>{` requires only little maintenance, thanks to its weekly rebalancing schedule.`}</p>
    </Summary>
    <Review mdxType="Review">
      <h2>{`Strategy Rules`}</h2>
      <Aside mdxType="Aside">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "512px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAE3klEQVQ4y2VUWWyUVRS+0iclvhgSeSAQjFAopWWVJeDGYgqiDxIMZakiRkz0wQVZhAiVLcGWgAoFElpppzNTpmWGLpS2086/3HPvv8zSzkwLEqG+woviE/2n95jzUwyJD+ffcu73f+d83zlMSsEcR7J79zKMc14kBDBNM5kQsBQAjgJAFAAMANABIAIA+6WEEsPglMMAoIjyEZFZlmA+WDptM9Pkkzj3k8oBoBsAkIJzjob5vygYJg/ejJszXFswW0LR72nB6upijI2ODj4L9jEAjBMIACjOuScEeBlXFCbCmwikGErKv1KOqEjagrk2FOm6yfwynwHDCbAxzrkSAlQ8Yarz4X51IdyvfgnF1c/BOJ5rjqufGvvGapv6sC9hYsoR69Ku8MunCwMB5ZzzcZNzNDkv0F03uEo7Qp1q6EX25m/I1jYi29CEbGNATXq/Wb28LYxLvmzzot06WoI/7B0wpgIQQ8NkAqDbloCOBWOO5d8VvQ8lJbZ0arh2XxQ/r+3CVz+5huv2RTGeMLF3wPRzpIAx27ZRCFEnpWTMkrCUGLX36Cp6S1fRbl1d79aR/hzp0rD1poaWAPxz2MZFX7Tilup2/CNn+a2htnAAFQyGUNO0R0NDmWlsJGMdbYwlkFU0eWxDk3ruvQAFsk0BfHFLEKduD2N924AvwpTKMG4+0o75tMSEZqDruiocbsGy8iWFUCiMd+6MVLGMK6LhDg2nf9TirfkuqmbuuobzPovg+VA/NsUSVJrPkMp8YXMQP/zxCaCmmwSIuz/do6bPmOXV1JzBfD53jkQxBQAdKtzNWqriYAzX7Y/h/byN1CNS3rUAW7s0ZO8GsPJYB2aTAi3bwYaGqzi3ZIGaXVzqHTr0A+Zy2RABGtQP0+SFpC1U6Z4Ivr036peoG0+MnHYEXmkdQLa+EXee7MRcSiAXEmOxG3j8xCm1bPlq78DBw5jP55sJMDLRYI/UJTuQkqTw08mg5xP1vchWNvgMn/YwlUqqnp5eNb9ssVdTewZHRoZrCHA/AUoBHvXp+Q+C6p0DMRxMiv8AiSGZ+KWtITxw4ZbPPqHpmMlk1Fdf78XZxaWFtrbrePv2yBYmJcwjM5P/Il2aYpsCauP3BChRN0w0DANN00Tg3BeCnunb0NCgqq9vUHNLyse3Vu5A27Yf6HpiCqOtYZg8RCwutPSPsTVXccPBmMo4gNKyMJ1Oo+s6aNsWOo6NyWQSU6mUunjxklq+4nUsnlP2uKkpgNns0MnR0fvMX0GdcWPGYFL8/Wuon8bL2368HW9nU+rS5Svqm2/3IXmso6MTYzfa1cVLl9Xu3XtU+YKlOPOVOWPV1ccxnU7dbQ4GJgvBmT/QlgSamArqV3uPjvGEMZZ0XbWzahcdUouXrFArVr6hXlu2SpXOX0TfxktKFz4+Wn0MHcf5xzSN8mTSJXKTmKYZNMtFUvig620JDy0pMd7fj6tWv1Ugj82ZW+bNKi71ZhfP9xYuWlao3FaFjY0BnxmBpdMp5jhOkZQW8zdtWnImBRTZElhfwpzKAeoA+KOWlmt4+nQNkmkPHz6CtWfOYiTSipZlPchlsyebmwOTiRmBua7r68FsW7CzZ68zWo5UPvWBc2C2LaeNjAxXDefz52gCcrlscHg4X0PWIDVJAMqlMokZgdH2/xceF02SwJ+/igAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "TuringTrader.com, Equal Voltage: A volatility-targeting strategy",
                "title": "TuringTrader.com, Equal Voltage: A volatility-targeting strategy",
                "src": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
                "srcSet": ["/static/2b23f891a5d398e021f35a8c1073fc00/5a46d/logo-512x512.png 300w", "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png 512w"],
                "sizes": "(max-width: 512px) 100vw, 512px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p><strong parentName="p">{` Learn More `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/volatility-targeting/"
            }}>{`Volatility Targeting`}</a></li>
        </ul>
      </Aside>
      <h3>{`Strategy Rules`}</h3>
      <p>{`The operation of `}<em parentName="p">{`Equal Voltage`}</em>{` can be summarized as follows:`}</p>
      <ul>
        <li parentName="ul">{`weight 11 sector indices inversely to their historical volatility`}</li>
        <li parentName="ul">{`invest any unused capital in U.S. treasuries`}</li>
      </ul>
      <p>{`These rules reflect the idea that in the long run, the U.S. stock market provides the best growth opportunities. Volatility poses the biggest threat this growth, which is addressed through reducing exposure in times of increasing volatility. We encourage investors keen on learning more about this rationale to read our article regarding volatility targeting.`}</p>
      <h2>{`Diversification`}</h2>
      <p>{`The strategy is well diversified in terms of individual titles, as it only invests in broad indices. `}<em parentName="p">{`Equal Voltage`}</em>{`'s low beta suggests the strategy being also well-diversified across asset classes. However, in times of low volatility, the strategy may allocate up to 100% of its capital toward the stock market, resulting in a tail risk similar to holding the S&P 500 index, should volatility spike rapidly.`}</p>
      <h2>{`Returns & Volatility`}</h2>
      <p>{`Like many active strategies, an essential part of returns stems from losing less than passive portfolios during recessions. Therefore, `}<em parentName="p">{`Equal Voltage`}</em>{`'s returns should be contemplated over the full economic cycle. `}</p>
      <p>{`The strategy beats a passive 60/40 in most years and managed the 2008 recession exceptionally well. However, it missed out on most of the 2009 rebound. `}<em parentName="p">{`Equal Voltage`}</em>{`'s maximum drawdown is less than that of a passive 60/40, but slightly amplifying short-term volatility. Overall, `}<em parentName="p">{`Equal Voltage`}</em>{` has shown docile behavior throughout the past economic cycle and has beaten both the 60/40 benchmark and the S&P 500 by a wide margin.`}</p>
      <p>{`The Monte-Carlo simulation confirms these observations: `}<em parentName="p">{`Equal Voltage`}</em>{` has a solid upside over a 60/40, while offering a comparable risk profile.`}</p>
      <h2>{`Account & Tax Considerations`}</h2>
      <p>{`When the strategy adjusts its sector exposure, it triggers taxable events. However, a significant portion of the portfolio’s assets is held for several years, qualifying for long-term treatment of capital gains. From the tax perspective, we see `}<em parentName="p">{`Equal Voltage`}</em>{` roughly on par with a 60/40 portfolio. This makes it a great candidate for taxable accounts.`}</p>
      <p>{`The strategy invests in up to 12 ETFs at a time. Therefore, it requires a minimum starting capital of $12,000.`}</p>
    </Review>
    <Notes mdxType="Notes">
      <p><strong parentName="p">{`Portfolio Revisions`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=1"
          }}>{`v1, November 2019`}</a>{`: Initial release.`}</li>
      </ul>
    </Notes>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      